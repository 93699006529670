@import "../normalize";
@import "../variables";

.header {
  background: url(../../images/mobile/image-header.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 3rem 2rem;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__hamburger {
      .line {
        width: 2.4rem;
        height: 0.2rem;
        background-color: $neutral-white;
      }
      .middle-line {
        margin: 0.6rem 0;
      }
    }
    &__desktop {
      display: none;
    }
  }

  &__main {
    font-family: "Fraunces", serif;
    text-transform: uppercase;
    text-align: center;
    color: $neutral-white;
    letter-spacing: 0.6rem;
    margin: 10rem 0;
    position: relative;

    &__arrow {
      margin-top: 3rem;
    }
  }

  &__menu {
    background-color: $neutral-white;
    padding: 5.5rem 9.3rem;
    text-align: center;
    visibility: hidden;
    font-family: "Barlow", sans-serif;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10rem;
    left: 1rem;

    &__link {
      text-decoration: none;
      color: $neutral-grayish-blue;
      margin-bottom: 3rem;
    }

    &__button {
      text-decoration: none;
      color: $neutral-grayish-blue;
      font-family: "Fraunces", serif;
      color: black;
      background-color: $primary-yellow;
      border-radius: 500px;
      padding: 1.8rem 4rem;
      text-transform: uppercase;
    }
  }

  &__menu--active {
    visibility: visible;
  }
}

@media screen and (min-width: 768px) {
  .header {
    padding: 3rem;
    background: url(../../images/desktop/image-header.jpg);
    background-size: cover;
    background-position: center center;

    &__menu--active {
      visibility: hidden;
    }

    &__nav {
      &__hamburger {
        visibility: hidden;
      }

      &__desktop {
        display: flex;
        align-items: center;

        &__link {
          margin-left: 3rem;
          margin-top: 0;
          text-decoration: none;
          color: $neutral-white;
        }

        &__button {
          margin-left: 3rem;
          text-decoration: none;
          color: black;
          background-color: $neutral-white;
          padding: 1.4rem 3rem;
          border-radius: 500px;
          text-transform: uppercase;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
    }
    &__main {
      &__title {
        font-size: 8rem;
        margin-bottom: 0;
      }

      &__arrow {
        margin-bottom: 31.2rem;
        transform: translateY(9rem);
      }
    }
  }
}
