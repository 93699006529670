$primary-soft-red: hsl(7, 99%, 70%);
$primary-yellow: hsl(51, 100%, 49%);
$primary-dark-des-cyan: hsl(167, 40%, 24%);
$primary-dark-blue: hsl(198, 62%, 26%);
$primary-dark-mod-cyan: hsl(168, 34%, 41%);

$neutral-very-dark-des-blue: hsl(212, 27%, 19%);
$neutral-very-dark-grayish-blue: hsl(213, 9%, 39%);
$neutral-dark-grayish-blue: hsl(232, 10%, 55%);
$neutral-grayish-blue: hsl(210, 4%, 67%);
$neutral-white: hsl(0, 0%, 100%);

html {
  font-size: 62.5%;
}

body {
  font-size: 1.8rem;
}
