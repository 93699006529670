@import "../normalize";
@import "../variables";

@mixin coverImage {
  width: 100%;
  height: 31.2rem;
  background-size: cover;
}

.grid {
  &--2 {
    background: url(../../images/mobile/image-transform.jpg);
    @include coverImage();
  }

  &--3 {
    background: url(../../images/mobile/image-stand-out.jpg);
    @include coverImage();
  }
}

.main {
  text-align: center;
  font-family: "Barlow", sans-serif;

  &__image {
    width: 100%;
  }

  &__text {
    padding: 4rem 2rem;
  }

  &__title {
    font-family: "Fraunces", serif;
    font-size: 3.2rem;
  }

  &__desc {
    color: $neutral-dark-grayish-blue;
    line-height: 2.8rem;
  }

  &__link {
    text-decoration: none;
    text-transform: uppercase;
    color: black;
    font-family: "Fraunces", serif;
    position: relative;
    z-index: 3;
  }

  &__link__highlight {
    width: 14rem;
    height: 0.8rem;
    border-radius: 500px;
    position: absolute;
    transform: translate(9.8rem, -0.7rem);
    z-index: 2;
    opacity: 0.3;
  }

  @mixin textEnd {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
  }

  .cherry-bg {
    height: 60rem;
    background: url(../../images/mobile/image-graphic-design.jpg);
    background-size: cover;
    background-position: center center;
    @include textEnd;
  }

  .orange-bg {
    height: 60rem;
    background: url(../../images/mobile/image-photography.jpg);
    background-size: cover;
    background-position: center center;
    @include textEnd;
  }

  .yellow {
    background: $primary-yellow;
  }

  .pink {
    background: $primary-soft-red;
  }

  .green {
    &-title {
      color: $primary-dark-des-cyan;
      margin-bottom: 1.6rem;
    }

    &-desc {
      color: $primary-dark-des-cyan;
      font-size: 1.6rem;
    }
  }

  .blue {
    &-title {
      color: $primary-dark-blue;
      margin-bottom: 1.6rem;
    }

    &-desc {
      color: $primary-dark-blue;
      font-size: 1.6rem;
    }
  }
}

@media screen and (min-width: 768px) {
  @mixin coverImage {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 60rem;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 60rem);
    place-items: center;
  }

  .grid--2 {
    background: url(../../images/desktop/image-transform.jpg);
    @include coverImage;
  }

  .grid--3 {
    background: url(../../images/desktop/image-stand-out.jpg);
    @include coverImage;
  }

  .main {
    &__link__highlight {
      transform: translate(-0.6rem, -0.6rem);
    }
    text-align: left;
    .grid {
      &--1 {
        grid-row: 1;
        grid-column: 1;
      }
      &--2 {
        grid-column: 2;
      }
      &--5 {
        padding: 0 15rem;
        text-align: center;
      }
      &--6 {
        padding: 0 15rem;
        text-align: center;
      }
    }
    &__text {
      padding: 4rem 13rem;
    }

    &__title {
      font-size: 4.2rem;
    }
    .cherry-bg {
      background: url(../../images/desktop/image-graphic-design.jpg);
      background-repeat: no-repeat;
      background-position: center;
    }
    .orange-bg {
      background: url(../../images/desktop/image-photography.jpg);
      background-repeat: no-repeat;
      background-position: center;
    }

    .green {
      &-desc {
        font-size: 1.8rem;
        margin-bottom: 5rem;
      }
    }
    .blue {
      &-desc {
        font-size: 1.8rem;
        margin-bottom: 5rem;
      }
    }
  }
}
