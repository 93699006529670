@import "../normalize";
@import "../variables";

.testimonials {
  text-align: center;
  font-family: "Barlow", sans-serif;
  color: $primary-dark-des-cyan;
  padding: 6rem 2.4rem;

  &__intro {
    font-family: "Fraunces", serif;
    color: $neutral-dark-grayish-blue;
    font-size: 1.6rem;
    text-transform: uppercase;
    letter-spacing: 0.5rem;
  }

  &__img {
    border-radius: 100%;
    width: 7rem;
    margin-top: 5rem;
    margin-bottom: 1rem;
  }

  &__quotes {
    line-height: 3rem;
  }

  &__author {
    color: black;
    font-family: "Fraunces", serif;
    margin-bottom: 0;
  }

  &__job {
    color: $neutral-dark-grayish-blue;
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 768px) {
  .grid-1x3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    column-gap: 4rem;
  }

  .testimonials {
    padding: 10rem 15rem;
    &__author {
      margin-top: 7rem;
    }
  }
}
