@import "../normalize";
@import "../variables";

.gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  .gallery__image {
    height: 18.8rem;
  }

  .image__milk {
    background: url(../../images/mobile/image-gallery-milkbottles.jpg);
    background-size: cover;
  }

  .image__orange {
    background: url(../../images/mobile/image-gallery-orange.jpg);
    background-size: cover;
  }

  .image__cone {
    background: url(../../images/mobile/image-gallery-cone.jpg);
    background-size: cover;
  }

  .image__sugar {
    background: url(../../images/mobile/image-gallery-sugar-cubes.jpg);
    background-size: cover;
  }
}

@media screen and (min-width: 768px) {
  .gallery {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;

    .gallery__image {
      height: 44.7rem;
    }

    .image__milk {
      background: url(../../images/desktop/image-gallery-milkbottles.jpg);
      background-size: cover;
    }

    .image__orange {
      background: url(../../images/desktop/image-gallery-orange.jpg);
      background-size: cover;
    }

    .image__cone {
      background: url(../../images/desktop/image-gallery-cone.jpg);
      background-size: cover;
    }

    .image__sugar {
      background: url(../../images/desktop/image-gallery-sugarcubes.jpg);
      background-size: cover;
    }
  }
}
