@import "../normalize";
@import "../variables";

.footer {
  padding: 6rem 3rem;
  background: #90d4c5;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: "Barlow", sans-serif;

  &__image {
    height: 40rem;
  }

  &__links {
    margin-top: 3rem;
  }

  &__link {
    text-decoration: none;
    padding: 0 2rem;
    color: $primary-dark-des-cyan;
  }

  &__socials {
    margin-top: 6rem;
  }

  &__icons {
    padding: 0 2rem;
    fill: $primary-dark-des-cyan;
  }
}

@media screen and (min-width: 768px) {
  .footer__socials {
    margin-top: 8rem;
  }
  .footer__links {
    margin-top: 5rem;
  }
}
